import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Visit `}<a parentName="p" {...{
        "href": "https://www.github.com/paresh140"
      }}>{`my Github`}</a>{` or find me on other platforms: `}<a parentName="p" {...{
        "href": "https://www.instagram.com/paresh_subedi/"
      }}>{`Instagram`}</a>{` & `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/paresh-subedi/"
      }}>{`LinkedIn`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      