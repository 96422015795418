import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I am Paresh Subedi from Kathmandu, Nepal.`}</p>
    </blockquote>
    <p>{`I am a Data Science student currently pursuing B.tech in Computer Science hons Data Science from Jain Deemed-to-be University.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      